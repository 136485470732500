import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpService } from '@core/services/http.service';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { TrafficCode } from '@core/enums/traffic.enum';
import { IconEnum } from '@shared/enums/icon.enum';
import { DocUpload } from '@core/models/outlook/docUpload.model';
import {
  OdataMasterDataResponse,
  OdataResponse,
} from '@shared/models/odata-response';
import { DocAttach } from '@core/models/outlook/docAttach.model';
import { DocDoraBase64Dto } from '@core/models/outlook/docDoraBase64.model';
import { BasicOperationInfoDto } from '../upload/models/basic-operation-info';
import { DocumentTypeResponseDto } from '../upload/models/document-type-response-dto';
import { LocalStorageService } from '@core/services/local-storage.service';
import { AuthorizeService } from '@core/services/authorize.service';
import { Organization } from '@core/models/organization';
import { TypeEndpointEnum } from '@core/enums/type-endpoint.enum';
import { RomeBasicInfoAndDocumentsDto } from '../upload/models/rome-basic-info-amd-documents';

const DORA_BASIC_URL = `${environment.operationsApiUrl}/api/v1/Operation`;
const ROME_BASIC_URL = `${environment.messagesApiUrl}/api/v1/RomeIntegration`;
@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  private spinnerVisibility = new BehaviorSubject<boolean>(false);
  public readonly onSpinnerVisibilityChange$ =
    this.spinnerVisibility.asObservable();

  constructor(
    private httpService: HttpService,
    private readonly localStorageService: LocalStorageService,
    private readonly authorizeService: AuthorizeService
  ) {}

  setSpinnerVisibility(spinnerVisibility: boolean) {
    this.spinnerVisibility.next(spinnerVisibility);
  }

  getSpinnerVisibility() {
    return this.spinnerVisibility.getValue();
  }

  public getBasicOperationInfo(
    operationNumber: string,
    isRome: boolean = false
  ): Observable<BasicOperationInfoDto> {
    const url = isRome ? ROME_BASIC_URL : DORA_BASIC_URL;
    return this.httpService.get(
      `${url}/${operationNumber}/basic-info`,
      {},
      false,
      true
    );
  }

  public getRomeBasicInfoAndDocuments(
    operationNumber: string,
    isRome: boolean = false
  ): Observable<RomeBasicInfoAndDocumentsDto> {
    const url = isRome ? ROME_BASIC_URL : DORA_BASIC_URL;
    return this.httpService.get(
      `${ROME_BASIC_URL}/${operationNumber}/basic-info-documents`,
      {},
      false,
      true
    );
  }

  public getDocumentTypes(
    operationNumber: string
  ): Observable<DocumentTypeResponseDto[]> {
    return this.httpService.get(
      `${environment.documentsApiUrl}/api/operations/${operationNumber}/documentTypes`
    );
  }
  public getMasterDocuments(): Observable<
    OdataMasterDataResponse<DocumentTypeResponseDto>
  > {
    return this.httpService.get(
      `${environment.masterApiUrl}/odata/MasterDocumentsOData?$count=true&$Filter=isActive%20eq%20true%20and%20isBlocked%20eq%20false`
    );
  }

  public getDoraDocument(
    operationNumber: string,
    type: string = TypeEndpointEnum.OPERATION
  ): Observable<OdataResponse<DocAttach>> {
    return this.httpService.get(
      `${environment.documentsApiUrl}/api/${type}/${operationNumber}/documents/attach-files-outlook`
    );
  }

  public getRomeDocument(ipda: number): Observable<DocAttach[]> {
    return this.httpService.get(
      `${ROME_BASIC_URL}/${ipda}/documents/attach-files-outlook`
    );
  }

  public getHeaderData(traffic: string): { title: string; iconName: string } {
    let data = {
      title: 'Ocean Operation',
      iconName: IconEnum.OceanBoat,
    };
    switch (traffic) {
      case TrafficCode.OCE:
        data = {
          title: 'Ocean Operation',
          iconName: IconEnum.OceanBoat,
        };
        break;
      case TrafficCode.AIR:
        data = {
          title: 'Air Operation',
          iconName: IconEnum.AirPlane,
        };
        break;
    }
    return data;
  }

  public uploadDocument(
    docUpload: DocUpload,
    operationNumber: string,
    type: string = TypeEndpointEnum.OPERATION
  ): Observable<any> {
    return this.httpService.post(
      `${environment.documentsApiUrl}/api/${type}/${operationNumber}/documents/upload-file-outlook`,
      docUpload
    );
  }

  public uploadRomeDocument(
    docUpload: DocUpload,
    operationNumber: string
  ): Observable<any> {
    return this.httpService.post(
      `${ROME_BASIC_URL}/${operationNumber}/documents/upload-file-outlook`,
      docUpload
    );
  }

  public getDoraDocumentBase64(
    documentIds: number[]
  ): Observable<DocDoraBase64Dto[]> {
    return this.httpService.get(
      `${environment.documentsApiUrl}/api/operation/documents/files-outlook?documentIds=${documentIds}`
    );
  }

  public changeOrganization(organization: Organization) {
    this.localStorageService.setItem(
      'Organization',
      JSON.stringify(organization)
    );
    this.authorizeService.organization.next(organization);
  }

  public getTypeEndpoint(operationFileNumber): string {
    if (operationFileNumber.toUpperCase().includes('OP')) {
      return TypeEndpointEnum.OPERATION;
    } else if (operationFileNumber.toUpperCase().includes('FL')) {
      return TypeEndpointEnum.FILE;
    } else {
      return TypeEndpointEnum.EXTERNAL;
    }
  }

  public isRome(operationFileNumber: string): boolean {
    return this.getTypeEndpoint(operationFileNumber) ===
      TypeEndpointEnum.EXTERNAL
      ? true
      : false;
  }
}
