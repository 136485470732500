<mat-paginator
  showFirstLastButtons
  [disabled]="disabled"
  [length]="length"
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
  [pageIndex]="pageIndex"
  [hidePageSize]="true"
  [showFirstLastButtons]="false"
  class="table-paginator dark:bg-neutral-800 dark:text-neutral-200"
  aria-label="Select table page"
  (page)="onPageChange($event)"
>
</mat-paginator>
