import {
  Component,
  OnInit,
  ViewEncapsulation,
  ElementRef,
  Renderer2
} from '@angular/core';
import { SpecialButtonComponent } from '../base/special-button.component';

@Component({
  selector: 'app-special-unread-button',
  templateUrl: '../base/special-button.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SpecialUnreadButtonComponent
  extends SpecialButtonComponent
  implements OnInit
{
  constructor(public el: ElementRef, public renderer: Renderer2) {
    super(el, renderer);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.renderer.addClass(
      this.el.nativeElement.firstElementChild,
      'unread-button'
    );
  }
}
