import { Injectable } from '@angular/core';

import { CustomString } from '@shared/utils/string';

@Injectable({
  providedIn: 'root',
})
export class PatternService {
  private readonly operationRegExp: RegExp = new RegExp(/^op(\d{8})$/gi);

  constructor() {}

  /**
   * @description match operation number code on text
   * @param  {string} operationNumber
   * @returns string
   */
  mathOperationPattern(operationNumber: string): boolean {
    if (
      !CustomString.IsNullOrBlank(operationNumber) &&
      this.operationRegExp.test(operationNumber)
    ) {
      return true;
    }

    return false;
  }
}
