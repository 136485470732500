import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Button } from '@core/models/buttons/button';
import { DocDownload } from '@core/models/outlook/docDownload.model';
import { Table } from '@core/models/table';
import { GridService } from '@core/services/grid.service';
import { OutlookService } from '@core/services/outlook.service';
import { DocumentTypeResponseDto } from '@feature/home/documents/upload/models/document-type-response-dto';
import { DocumentTypeEnum } from '@shared/enums/document-type.enum';
import { CustomString } from '@shared/utils/string';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  animations: [],
})
export class TableComponent<T> implements OnInit, OnDestroy {
  public tableProps: Table<T>;
  public selectedData: Array<T>;
  public metaKeySelection: boolean = true;
  public blockChars: RegExp = /^[\w\-\s]+$/;

  private subscription: Subscription = new Subscription();

  @Input() documentsTypes: DocumentTypeResponseDto[];
  @Input() button: Button;
  @Input()
  get props(): Table<T> {
    return this.tableProps;
  }
  set props(props: Table<T>) {
    this.tableProps = props;
  }
  @Output() _getDocument = new EventEmitter<DocDownload>();
  @Output() _getDocumentList = new EventEmitter<DocDownload[]>();

  private documentsList: DocDownload[] = [];

  public saveButton: Button = {
    text: 'Upload documents',
    onClick: () => this.uploadDocuments(),
    disabled: true,
  };

  constructor(
    private readonly outlookService: OutlookService,
    private readonly gridService: GridService<T[]>
  ) {}

  ngOnInit(): void {
    if (this.button) {
      this.saveButton = {
        ...this.button,
      };
    }
  }

  ngOnDestroy(): void {
    this.gridService.clearCheckedRowList();
    this.subscription?.unsubscribe();
  }

  emitGetDocument(document: DocDownload): void {
    this._getDocument.emit(document);
  }

  emitGetDocumentList(documentList: DocDownload[]): void {
    this._getDocumentList.emit(documentList);
  }

  onRowSelect(event) {
    this.gridService.setCheckedRowValue(event.data);
  }

  onRowUnselect(event) {
    this.gridService.deleteCheckedRowValue(event.data);
  }

  selectRow() {
    if (Array.isArray(this.selectedData) && this.selectedData.length > 0) {
      this.saveButton.disabled = false;
      if (this.selectedData.every((data) => data instanceof DocDownload)) {
        this.addToDocumentsList();
      }
    } else {
      this.saveButton.disabled = true;
    }
    this.saveButton = {
      ...this.saveButton,
    };
  }

  addToDocumentsList() {
    this.documentsList = this.selectedData as DocDownload[];
    this.documentsList.forEach((doc) => {
      if (CustomString.IsNullOrBlank(doc.base64)) {
        this.outlookService.getFileFromOutlookCloud(doc);
      }
    });
  }

  uploadDocuments() {
    if (Array.isArray(this.documentsList) && this.documentsList.length > 0) {
      this.emitGetDocumentList(this.documentsList);
    }
  }

  public getDataSource(): T[] {
    let dataSources: T[] = [];
    if (
      Array.isArray(this.props?.dataSource) &&
      this.props?.dataSource.length > 0
    ) {
      dataSources = this.props.dataSource;
    }
    return dataSources;
  }

  public isFirstEmail(rowIndex: number, description: string): boolean {
    return rowIndex === 0 && !CustomString.IsNullOrBlank(description) && description === DocumentTypeEnum.Email
      ? true
      : false;
  }
}
