import { AbstractControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Tab } from '@core/models/layout/tab';
import { TabList } from '@core/models/layout/tab-list';
import { TabEnum } from '@shared/enums/tab.enum';

@Injectable({
  providedIn: 'root',
})
export class TabService {
  private internalTabList: TabList;

  public get tabList(): TabList {
    return this.internalTabList;
  }

  public set tabList(tabList: TabList) {
    this.internalTabList = tabList;
    if (tabList && tabList !== null) {
      const activeTab = tabList.tabs.find((tab: Tab) => tab.active);
      this.lastActivedTab = activeTab ? activeTab : tabList?.tabs[0];
      this.lastActivedTabIndex = tabList.tabs.indexOf(this.lastActivedTab);
    } else {
      this.lastActivedTab = null;
      this.lastActivedTabIndex = null;
    }
  }

  private activeTab = new BehaviorSubject<string>('');
  public readonly onActiveTab$ = this.activeTab.asObservable();

  private readonly activeTabIndexSub = new BehaviorSubject<number>(0);
  public readonly onTabIndexChanges$ = this.activeTabIndexSub.asObservable();
  private readonly activeTabId = new BehaviorSubject<TabEnum>(null);
  public readonly onActiveTabIdChanges$ = this.activeTabId.asObservable();

  public lastActivedTab: Tab;
  public lastActivedTabIndex: number = null;

  public changeActiveTabIndex(tabPosition: number): void {
    this.activeTabIndexSub.next(tabPosition);
  }

  public changeActiveTabId(tabId: TabEnum): void {
    this.activeTabId.next(tabId);
  }

  setActiveTab(tabId: string) {
    this.activeTab.next(tabId);
  }

  getActiveTab() {
    return this.activeTab.getValue();
  }

  // To active current TABS
  setActiveTabById(tobeActivedTabId?: string) {
    const tabToActivate = this.tabList?.tabs.find(
      (tab) => tab.tabId === tobeActivedTabId
    );
    if (tabToActivate) {
      const activeTabs = this.tabList.tabs.find((tab) => tab.active === true);
      if (activeTabs) {
        activeTabs.active = false;
      }
      this.tabList.tabs[this.tabList.tabs.indexOf(tabToActivate)].active = true;
      this.tabList = {
        ...this.tabList,
      };
    }
  }
}
