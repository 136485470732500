<button
  mat-stroked-button
  [id]="buttonId"
  [ngClass]="getClassName()"
  [attr.aria-label]="!props.text && props.ariaLabel ? props.ariaLabel : null"
  [(disabled)]="props.disabled ? props.disabled : disabled"
  (click)="!disabled && props.onClick && props.onClick($event)"
  [type]="props.type ? props.type : 'button'"
  [tabindex]="props?.tabindex ? props.tabindex : 0"
  >
  @if (props.icon) {
    <mat-icon
      [svgIcon]="props.icon.name"
      [ngClass]="getClassIcon()"
    ></mat-icon>
  }
  @if (props.text) {
    <span [ngClass]="props.icon ? 'ml-2' : 'null'">{{
      props.text
    }}</span>
  }
  @if (props.boldText) {
    <strong>&nbsp;{{ props.boldText }}</strong>
  }
  @if (props.arrow) {
    <mat-icon
      svgIcon="pointer-down"
      class="mat-icon-sm movable-arrow ml-1"
    ></mat-icon>
  }
</button>
