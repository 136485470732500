import {
  Component,
  HostBinding,
  Input,
  ViewEncapsulation
} from '@angular/core';

import { Badge } from '@core/models/indicators/badge';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BadgeComponent {
  @Input() props: Badge;
  @HostBinding('class') public get hostClass() {
    return this.props.inline ? 'inline-flex' : '';
  }

  constructor() {}
}
