import { Subject, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GridService<T> {
  public checkedRowList: T[] = [];

  private checkedRows = new Subject<T[]>();
  public readonly onRowChecked$ = this.checkedRows.asObservable();

  getCheckedRowList() {
    return this.checkedRowList;
  }

  setCheckedRowValue(value: T) {
    if (!this.checkedRowList.find((item) => Object.is(item, value))) {
      this.checkedRowList.push(value);
    }
    this.checkedRows.next(this.checkedRowList);
  }

  clearCheckedRowList() {
    this.checkedRowList = [];
    this.checkedRows.next(this.checkedRowList);
  }

  deleteCheckedRowValue(value: T) {
    if (this.checkedRowList.find((item) => Object.is(item, value))) {
      this.checkedRowList = this.checkedRowList.filter((x) => x !== value);
    }
    this.checkedRows.next(this.checkedRowList);
  }
}
