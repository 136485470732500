import { Component } from '@angular/core';
import { LoadingService } from '@core/services/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public get isLoading(): boolean {
    return this.loadingService.isLoading;
  }

  public get loadingText(): string | null {
    return this.loadingService.loadingText;
  }

  public get initiaLoading(): boolean {
    return this.loadingService.initiaLoading;
  }

  constructor(private readonly loadingService: LoadingService) {}
}
