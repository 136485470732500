import { Component, HostBinding, Input } from '@angular/core';
@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {
  @HostBinding('class') class = 'spinner';
  private overlaySpacingClass: string;

  @Input() message = 'Loading data...';
  @Input() overlayBg: string;
  @Input()
  get overlaySpacing(): string {
    return this.overlaySpacingClass;
  }

  set overlaySpacing(overlayClass: string) {
    const SPACING_CLASS_REGEX = /([pm][lrtbxy])-(\d){1,2}/;
    const filteredSpacing = overlayClass
      .split(/\s/)
      .filter(className => new RegExp(SPACING_CLASS_REGEX).test(className));

    // NOTE: THIS IS TO ENSURE ONLY SPACING CLASSES ARE ADDED TO OVERLAY ELEMENT
    if (filteredSpacing.length) {
      overlayClass = filteredSpacing.join(' ');
    }
    this.overlaySpacingClass = overlayClass;
  }
}
