<div
  class="flex flex-wrap overflow-hidden tab-list border-line border-b px-6 dark:bg-neutral-800"
  #tabList
  [ngClass]="props.readonly ? 'bg-inactive' : ''"
  >
  @for (tab of localTabs; let i = $index; track trackBy(i)) {
    <div
      (click)="tabChange(i)"
      [id]="getTabId(tab)"
      class="tab"
      [tabindex]="0"
      >
      <div class="tab-label flex items-center">
        <span>{{ tab.label }}</span>
      </div>
    </div>
  }
  <div class="tab-indicator"></div>
</div>
<ng-container #tabContent></ng-container>
