<div
  class="overlay"
  [ngClass]="[
    overlaySpacing ? overlaySpacing : '',
    overlayBg ? overlayBg : 'bg-neutral-10'
  ]"
>
  <div class="row">
    <div class="col flex justify-center">
      <mat-spinner
        color="inform-500"
        mode="indeterminate"
        strokeWidth="7"
        diameter="80"
      ></mat-spinner>
    </div>
    <div class="w-full"></div>
    <div class="col flex justify-center mt-6">
      <p class="text-lg text-neutral-600">{{ message }}</p>
    </div>
  </div>
</div>
