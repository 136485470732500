import { BehaviorSubject, Subscription } from 'rxjs';
import {
  Log,
  Profile,
  User,
  UserManager,
  UserManagerSettings,
} from 'oidc-client';

import { Company } from '@core/models/company';
import { CustomObject } from '@shared/utils/object';
import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { Organization } from '@core/models/organization';
import { OrganizationCodeEnum } from '@core/enums/organization-code.enum';
import { OrganizationConfig } from '@core/models/organization-config';
import { environment } from '@env';
import { User as userModel } from '@core/models/user';
import { SnackbarService } from './snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizeService {
  private readonly subscription: Subscription = new Subscription();
  private readonly manager: UserManager = new UserManager(
    this.getClientSettings()
  );
  private readonly managerUser: BehaviorSubject<User> =
    new BehaviorSubject<User>(null);
  public readonly onUserAuthenticated$ = this.managerUser.asObservable();
  private readonly orgWithInvoicesSeries = [
    OrganizationCodeEnum.TibaRepDominicana,
    OrganizationCodeEnum.TibaPeru,
    OrganizationCodeEnum.TibaEcuador,
    OrganizationCodeEnum.TibaChina,
    OrganizationCodeEnum.TibaChinaSZ,
  ];

  private get profile(): Profile {
    return this.managerUser.getValue()?.profile;
  }

  private get organizationList(): Organization[] {
    let orgList: Organization[] = null;
    if (this.profile?.companies) {
      const companies: Organization | Organization[] = this.profile
        ?.companies as Organization | Organization[];
      if (Array.isArray(companies)) {
        orgList = [...companies];
      } else {
        orgList = [];
        orgList.push(companies);
      }
    }
    return orgList;
  }

  public get organizationId(): number {
    let orgId = null;
    if (
      this.organization &&
      this.organizationList &&
      this.organizationList.length > 0
    ) {
      orgId = this.organization.getValue()?.id;
    }
    return orgId;
  }

  private get organizationCode(): string {
    let orgCode = null;
    if (
      this.organization &&
      this.organizationList &&
      this.organizationList.length > 0
    ) {
      orgCode = this.organization.getValue()?.code;
    }
    return orgCode;
  }

  private get organizationName(): string {
    let orgName = null;
    if (
      this.organization &&
      this.organizationList &&
      this.organizationList.length > 0
    ) {
      orgName = this.organization.getValue()?.name;
    }
    return orgName;
  }

  private get type(): string {
    if (this.profile?.idp === 'AzureAD') {
      return 'Corporate';
    }
    return 'External';
  }

  private get name(): string {
    return this.profile ? this.profile?.name : '';
  }

  private get nick(): string {
    return this.profile?.nickname ?? this.profile?.preferred_username;
  }

  private get branch(): string {
    try {
      return this.managerUser.getValue() !== null
        ? JSON.parse(this.profile?.organization).branch
        : '';
    } catch (error) {
      return null;
    }
  }

  private get department(): string {
    try {
      return this.managerUser.getValue() !== null
        ? JSON.parse(this.profile?.organization).deparment
        : '';
    } catch (error) {
      return null;
    }
  }

  private get roleList(): string[] {
    try {
      const selectedOrganization = this.organizationList.find(
        (x) => x.id === this.organizationId
      );
      return selectedOrganization?.roles;
    } catch (error) {
      return null;
    }
  }

  private get groups(): string[] {
    try {
      if (this.managerUser.getValue() !== null) {
        return JSON.parse(this.profile?.organization).groups;
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  private get image(): string {
    return this.profile?.profile
      ? this.profile.profile
      : 'assets/images/avatar-default.png';
  }

  private get phone(): string {
    return this.managerUser.getValue() !== null
      ? this.profile?.phone_number
      : '';
  }

  private get mobilePhone(): string {
    try {
      return this.managerUser.getValue() !== null
        ? JSON.parse(this.profile?.contact_details).mobile_work
        : '';
    } catch (error) {
      return null;
    }
  }

  private get workExtension(): string {
    try {
      return this.managerUser.getValue() !== null
        ? JSON.parse(this.profile?.contact_details).work_extension
        : '';
    } catch (error) {
      return null;
    }
  }

  private get mobileExtension(): string {
    try {
      return this.managerUser.getValue() !== null
        ? JSON.parse(this.profile?.contact_details).mobile_extension
        : '';
    } catch (error) {
      return null;
    }
  }

  private get email(): string {
    return this.profile ? this.profile?.email : '';
  }

  private get permissions(): string[] {
    try {
      const selectedOrganization = this.organizationList.find(
        (x) => x.id === this.organizationId
      );
      return selectedOrganization?.permissions;
    } catch (error) {
      return null;
    }
  }

  public organizationConfig: OrganizationConfig[] = [];

  public organization: BehaviorSubject<Organization> =
    new BehaviorSubject<Organization>(null);

  public get isAuthenticated(): boolean {
    const managerUser = this.managerUser.getValue();
    return managerUser && !managerUser.expired;
  }

  public get authorizationHeaderValue(): string {
    const managerUser = this.managerUser.getValue();
    return `${managerUser.token_type} ${managerUser.access_token}`;
  }

  public get user(): userModel {
    return {
      nick: this.nick,
      image: this.image,
      organization: this.organizationList,
      organizationId: this.organizationId,
      organizationCode: this.organizationCode,
      organizationName: this.organizationName,
      name: this.name,
      email: this.email,
      branch: this.branch,
      department: this.department,
      roles: this.roleList,
      groups: this.groups,
      phone: {
        number: this.phone,
        extension: this.workExtension,
      },
      mobile: {
        number: this.mobilePhone,
        extension: this.mobileExtension,
      },
      status: 'Active',
      permissions: this.permissions,
      type: this.type,
    };
  }

  get isEcuador() {
    return this.organizationCode === OrganizationCodeEnum.TibaEcuador;
  }

  get isPeru() {
    return this.organizationCode === OrganizationCodeEnum.TibaPeru;
  }

  get isMozambique() {
    return this.organizationCode === OrganizationCodeEnum.TibaMozambique;
  }

  get isChina() {
    return [
      OrganizationCodeEnum.TibaChina,
      OrganizationCodeEnum.TibaChinaSZ,
      OrganizationCodeEnum.TibaChinaHK,
    ].includes(<OrganizationCodeEnum>this.organizationCode);
  }

  get isChinaWithoutHK() {
    return [
      OrganizationCodeEnum.TibaChina,
      OrganizationCodeEnum.TibaChinaSZ,
    ].includes(<OrganizationCodeEnum>this.organizationCode);
  }

  get isHongKong() {
    return [OrganizationCodeEnum.TibaChinaHK].includes(
      <OrganizationCodeEnum>this.organizationCode
    );
  }

  get isSpain() {
    return this.organizationCode === OrganizationCodeEnum.TibaSpain;
  }

  get isUSA() {
    return this.organizationCode === OrganizationCodeEnum.TibaUSA;
  }

  get isMexico() {
    return this.organizationCode === OrganizationCodeEnum.TibaMexico;
  }

  get isPortugal() {
    return this.organizationCode === OrganizationCodeEnum.TibaPortugal;
  }

  get isAngola() {
    return this.organizationCode === OrganizationCodeEnum.TibaAngola;
  }

  get isCaboVerde() {
    return this.organizationCode === OrganizationCodeEnum.TibaCaboVerde;
  }

  get orgWithInvoiceSeries() {
    return this.orgWithInvoicesSeries.includes(
      this.organizationCode as OrganizationCodeEnum
    );
  }

  get getLocalCurrencyId() {
    return (JSON.parse(localStorage.getItem('Company')) as Company).currencyId;
  }

  constructor(
    private readonly httpService: HttpService,
    private snackbarService: SnackbarService
  ) {
    Log.logger = console;
    Log.level = Log.INFO;

    this.setManagerUser();

    this.manager.events.addUserLoaded(() => {
      this.setManagerUser();
    });

    this.subscription.add(
      this.organization.subscribe((organization) => {
        if (!CustomObject.isEmpty(organization)) {
          this.setConfiguration(organization);
        }
      })
    );
  }

  private setManagerUser() {
    this.manager.getUser().then((user) => {
      if (user) {
        this.managerUser.next(user);
        this.setOrganization();
      }
    });
  }

  private getClientSettings(): UserManagerSettings {
    return {
      authority: environment.identityserver,
      client_id: environment.client_id,
      redirect_uri: environment.redirect_uri,
      post_logout_redirect_uri: environment.post_logout_redirect_uri,
      response_type: 'code',
      scope: environment.scope,
      automaticSilentRenew: true,
      silent_redirect_uri: environment.silent_redirect_uri,
    };
  }

  private setOrganization() {
    const organization = localStorage.getItem('Organization');
    if (organization !== null && organization.length > 0) {
      this.organization.next(JSON.parse(organization));
    } else if (
      this.organizationList !== null &&
      this.organizationList.length > 0
    ) {
      this.organization.next(this.organizationList[0]);
    }
  }

  private setConfiguration(organization: Organization) {
    this.loadCompany(organization.id);
    this.loadCompanyConfiguration(organization.id);
    if (organization !== null) {
      this.user.organizationId = organization.id;
    }
  }

  private loadCompany(organizationId: number): void {
    this.subscription.add(
      this.httpService
        .get(`${environment.masterApiUrl}/api/v1/Company/${organizationId}`)
        .pipe(
          this.snackbarService.openCatchErrorSnackbar('Unable to load company')
        )
        .subscribe((company: Company) => {
          const localCompany: Company = {
            countryCode: company.countryCode,
            currencyId: company.currencyId,
            countryId: company.countryId,
          };
          localStorage.setItem('Company', JSON.stringify(localCompany));
        })
    );
  }

  private loadCompanyConfiguration(organizationId: number): void {
    this.subscription.add(
      this.httpService
        .get(
          `${environment.masterApiUrl}/api/v1/CompanyConfiguration/CompanyConfig?idCompany=${organizationId}`
        )
        .subscribe(
          (config: OrganizationConfig[]) => {
            this.organizationConfig = config;
            localStorage.setItem('Configuration', JSON.stringify(config));
          },
          (error) => {
            if (error?.status !== 404) {
              this.snackbarService.openErrorSnackbar(
                'Unable to load company configuration'
              );
            }
            this.organizationConfig = [];
            localStorage.setItem('Configuration', JSON.stringify([]));
          }
        )
    );
  }

  private setLocalStorage(): void {
    if (this.organizationList !== null && this.organizationList.length > 0) {
      localStorage.setItem(
        'Organization',
        JSON.stringify(this.organizationList[0])
      );
    }
  }

  public login() {
    return this.manager.signinRedirect();
  }

  public async logout() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      await this.manager.signoutRedirect();
    }
  }

  public async completeAuthentication() {
    this.managerUser.next(await this.manager.signinRedirectCallback());
    try {
      const org = localStorage.getItem('Organization');
      if (org) {
        let selectedCompany: any;
        try {
          selectedCompany = JSON.parse(org);
        } catch (error) {
          if (
            this.organizationList !== null &&
            this.organizationList.length > 0
          ) {
            selectedCompany = this.organizationList[0];
            this.setLocalStorage();
          } else {
            console.log('completeAuthentication', error);
          }
        }
        if (
          this.organizationList &&
          !this.organizationList.some(
            (element) => element.id === selectedCompany.id
          )
        ) {
          this.setLocalStorage();
        }
      } else {
        this.setLocalStorage();
      }
    } catch (error) {
      console.log('completeAuthentication', error);
    }
  }

  public getAccessToken(): string {
    return this.managerUser.getValue()?.access_token;
  }

  public hasRole(role: string): boolean {
    return this.user?.roles?.includes(role);
  }

  public hasPermission(permission: string): boolean {
    return this.user?.permissions?.includes(permission);
  }

  public isRolePermited(role: string): boolean {
    return this.roleList?.includes(role);
  }
}
