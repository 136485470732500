export class CustomString {
  public static capitalize(str: string): string {
    return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
  }

  public static unCapitalize(str: string): string {
    return str.charAt(0).toLowerCase() + str.slice(1);
  }

  public static capitalizeWords(str: string) {
    const splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }

  public static camelize(str: string): string {
    return CustomString.unCapitalize(str).replace(
      /\W+(.)/g,
      (_match, char: string) => char.toUpperCase()
    );
  }

  public static stringToBoolean(stringValue: string): boolean | undefined {
    try {
      return Boolean(JSON.parse(stringValue));
    } catch (e) {
      return undefined;
    }
  }

  public static IsNullOrBlank(value: string | object): boolean {
    try {
      if (value === null || value === undefined) {
        return true;
      }

      return value.toString().replace(/\s/g, '').length < 1;
    } catch (e) {
      return false;
    }
  }

  public static htmlToString(html: string): string {
    const elem = document.createElement('DIV');
    elem.innerHTML = html;
    const text: string = elem.textContent || elem.innerText || '';
    elem.remove();
    return text;
  }
}
