import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';

import { Button } from '@core/models/buttons/button';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-standard-button',
  templateUrl: './standard-button.component.html'
})
export class StandardButtonComponent implements OnInit {
  protected internalValue: any;

  @ViewChild(MatButton) private readonly matButton: MatButton;

  @Input()
  props: Button;

  @Input()
  get disabled() {
    return this.internalValue;
  }
  set disabled(newValue) {
    if (this.internalValue !== newValue) {
      this.internalValue = newValue;
      this.disabledChange.emit(this.internalValue);
    }
  }

  @Output() disabledChange: EventEmitter<any> = new EventEmitter<any>();

  public get buttonId() {
    return this.props?.text?.replace(/ /g, '-').toLowerCase() || '';
  }

  constructor(public el: ElementRef, public renderer: Renderer2) {}

  ngOnInit(): void {
    this.renderer.addClass(
      this.el.nativeElement.firstElementChild,
      'standard-button'
    );
  }

  getClassName() {
    return `${!this.props.text ? 'icon-only' : '!px-3'} ${
      this.props.class ? this.props.class : ''
    }`;
  }

  getClassIcon() {
    const iconSizeClass = this.props?.icon?.class
      ? this.props.icon.class
      : 'mat-icon-sm';
    const iconColorClass = this.props.icon.color ? this.props.icon.color : '';
    return `${iconSizeClass} ${iconColorClass}`;
  }

  focus() {
    this.matButton.focus();
  }
}
