export enum DocumentTypeEnum {
  ShippingInstruction = 'SI',
  HouseBL = 'HBL',
  BookingRequest = 'RESERVA',
  CustomsInstructions = 'DESPACHO',
  ServiceConfirmationCustomer = 'BK_CONF_C',
  ServiceConfirmationAgent = 'BK_CONF_A',
  InsuranceRequest = 'SOL_INS',
  OtherCustomACI = 'ACI',
  OtherCustomAFR = 'AFR',
  OtherCustomAMS = 'AMS',
  OtherCustomCCAM = 'CCAM',
  OtherCustomISF = 'ISF',
  OriginCertificate = 'CERT_OR',
  PreAlert = 'PRE',
  DepartureConfirmation = 'DEP_NOT_T',
  ArrivalNotice = 'ARR_NOT_T',
  ArrivalDateRequest = 'SOL_FLLEG',
  RoutingOrder = 'R_O',
  DeliveryInstructionsRequest = 'SOL_IE',
  SendingDocuments = 'ENV_DOC',
  ReceiptDocumentsConfirmation = 'ACUSE_T',
  UnloadDateRequest = 'sol_fecha_desc',
  CustomDetailRequest = 'DATOS',
  ServiceAcceptance = 'ACE_SER',
  FolderOriginA4 = 'FILE',
  FolderOriginA3 = 'FILE_A3',
  Warehouse = 'ENT',
  CargoManifest = 'MANIF_SAL',
  HAWB = 'HAWB_16',
  MAWB = 'MAWB_16',
  HAWBFiles = 'HAWB_1',
  MAWBFiles = 'MAWB_1',
  CoverA4 = 'COVER',
  CoverA3 = 'COVER_A3',
  Label = 'LABEL',
  GuaranteeInstructions = 'GUARANTIE',
  Delegateistructions = 'CAR_MX',
  ShippingInstructionCh = 'SI_CH',
  Worksheet = 'WS',
  Email = 'EMAIL',
  CustomsRequest = 'SOL',
  ForwarderCertificate = 'FWD_CERT',
  HouseBLSwitch = 'S-HBL',
  HouseBLDestination = 'Hbl_Destin',
  TransportOrdFCL= 'TRA_ORD_FCL',
  TransportOrdLCL= 'TRA_ORD_LCL',
  TransportOrder = 'TRA_ORD',
  MultiDocuments = 'MULTI'
}
