import { Router } from '@angular/router';

import { AuthorizeService } from '@core/services/authorize.service';
import { Injectable } from '@angular/core';
import { ROUTES_DEFINITION } from '@shared/constants/router-definitions.constant';

@Injectable({ providedIn: 'root' })
export class AuthorizeGuard {
  constructor(private authorize: AuthorizeService, private router: Router) {}

  canActivate(): boolean {
    const isAuthenticated = this.authorize.isAuthenticated;
    if (!isAuthenticated) {
      this.router.navigate([ROUTES_DEFINITION.LOGIN]);
      return false;
    }
    return true;
  }
}
