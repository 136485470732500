<button
  #specialButton
  mat-mini-fab
  [ngClass]="
    (props.text ? '!px-3' : '') + ' ' + (props.class ? props.class : '')
  "
  [attr.aria-label]="!props.text && props.ariaLabel ? props.ariaLabel : null"
  [type]="props.type ? props.type : 'button'"
  [(disabled)]="props.disabled ? props.disabled : disabled"
  (click)="props.onClick && props.onClick()"
  [tabindex]="props.tabindex ? props.tabindex : ''"
  >
  @if (props.icon) {
    <mat-icon
      [svgIcon]="props.icon.name"
      [ngClass]="props.icon.color ? props.icon.color : ''"
      class="mat-icon-sm"
    ></mat-icon>
  }
  @if (props.text) {
    <span [ngClass]="props.icon ? 'ml-2' : 'null'">{{
      props.text
    }}</span>
  }
</button>
