import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { PaginatorModule } from 'primeng/paginator';
import { ToastModule } from 'primeng/toast';
import { KeyFilterModule } from 'primeng/keyfilter';


const exportCurrentModule = [
  TableModule,
  InputTextModule,
  DropdownModule,
  InputMaskModule,
  PaginatorModule,
  ToastModule,
  KeyFilterModule
];

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [...exportCurrentModule],
})
export class PrimeNgModule {}
