import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

import { Pill } from '@core/models/indicators/pill';

@Component({
  selector: 'app-pill',
  templateUrl: './pill.component.html',
  styleUrls: ['./pill.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PillComponent implements OnInit {
  @Input() props: Pill;

  constructor() {}

  ngOnInit(): void {}
}
