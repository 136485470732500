import { Injectable, Injector } from '@angular/core';
import { OutlookService } from './outlook.service';

@Injectable()
export class InitializeService {
  constructor(private _injector: Injector) {}

  /**
   * @description Previous init WebApp and get outlook token
   */
  initializeApp(): Promise<any> {
    const _outlookService: OutlookService = this._injector.get(OutlookService);

    return new Promise((resolve: any) => {
      _outlookService.getOutlookToken();
      resolve();
    });
  }
}
