import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';

import { Button } from '@core/models/buttons/button';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-special-button',
  templateUrl: './special-button.component.html'
})
export class SpecialButtonComponent implements OnInit {
  @Input()
  props: Button;

  protected internalValue: any;

  @Input()
  get disabled() {
    return this.internalValue;
  }
  set disabled(newValue) {
    if (this.internalValue !== newValue) {
      this.internalValue = newValue;
      this.disabledChange.emit(this.internalValue);
    }
  }

  @Output() disabledChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('specialButton') specialButton: MatButton;

  constructor(public el: ElementRef, public renderer: Renderer2) {}

  ngOnInit(): void {
    this.renderer.addClass(
      this.el.nativeElement.firstElementChild,
      'special-button'
    );
  }
  focusElement() {
    this.specialButton.focus();
  }
}
