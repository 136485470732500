import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  RendererStyleFlags2,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

import { CustomObject } from '@shared/utils/object';
import { Subscription } from 'rxjs';
import { Tab } from '@core/models/layout/tab';
import { TabList } from '@core/models/layout/tab-list';
import { TabService } from '@core/services/tab.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  @Input() props: TabList;
  @Input() classList: string;
  @Input() dataSource: any[];
  @Input() zIndex = 1;
  @Output() public tabChangeEvent = new EventEmitter<any>();
  @Output() public loadedTabComponentEvent = new EventEmitter<
    ComponentRef<any>
  >();

  @ViewChild('tabList', { read: ElementRef })
  tabList: ElementRef;
  @ViewChild('tabContent', { read: ViewContainerRef })
  viewContainerRef: ViewContainerRef;

  public isScrollable: boolean;

  private componentRef: ComponentRef<any>;
  private currentActiveTabIndex: number;
  private tobeActivedTabIndex: number;
  public localTabs: Tab[] = [];

  private routerSubscription: Subscription;
  private readonly subscription = new Subscription();

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly el: ElementRef,
    private readonly renderer: Renderer2,
    private readonly tabService: TabService,
    private readonly router: Router
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.props?.tabs) {
      // elimino las tab que tengan hidden en true

      this.localTabs = this.props?.tabs.filter((item) => !item.hidden);

      let activeTabIndex = this.localTabs.findIndex(
        (tab: Tab) => tab.active === true
      );
      if (activeTabIndex === -1) {
        activeTabIndex = 0;
        this.currentActiveTabIndex = 0;
      }
      if (
        activeTabIndex !== -1 &&
        this.currentActiveTabIndex !== undefined &&
        activeTabIndex !== this.currentActiveTabIndex
      ) {
        this.tabChange(activeTabIndex);
      }

      if (changes?.props?.previousValue) {
        const previousTabList = <TabList>changes.props.previousValue;

        const currentTabList = <TabList>changes.props.currentValue;

        const previousTabs = previousTabList.tabs;

        const currentTabs = currentTabList.tabs;

        if (previousTabs.length) {
          const previousTabProps =
            previousTabs[this.currentActiveTabIndex]?.content?.props;

          const currentTabProps =
            currentTabs[this.currentActiveTabIndex]?.content?.props;

          if (
            !CustomObject.compare(previousTabProps ?? [], currentTabProps ?? [])
          ) {
            this.renderComponent(this.currentActiveTabIndex);
          }
        } else {
          if (currentTabs.length !== previousTabs.length) {
            this.renderComponent(this.currentActiveTabIndex);
            this.setIndicatorProperties(this.currentActiveTabIndex);
          }
        }
      }
    }
  }

  ngOnInit() {
    if (this.props) {
      this.tabService.tabList = this.props;
    }
    this.renderer.addClass(this.el.nativeElement, 'w-full');
    if (
      this.props.classList &&
      this.props.classList.find((el) => el === 'scrollable')
    ) {
      this.isScrollable = true;
    }

    this.routerSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe(() => {
        this.tabService.tabList = this.props;
      });
  }

  ngAfterViewInit(): void {
    if (this.props.classList) {
      this.props.classList.forEach((className) => {
        this.renderer.addClass(this.tabList.nativeElement, className);
      });
    }

    if (this.localTabs?.length > 0) {
      const findActiveTabIndex = this.localTabs?.findIndex((el) => el?.active);
      const activeTabIndex = findActiveTabIndex !== -1 ? findActiveTabIndex : 0;

      this.renderComponent(activeTabIndex);
      this.setIndicatorProperties(activeTabIndex);
      this.setActiveClass(activeTabIndex);

      this.currentActiveTabIndex = activeTabIndex;
    }

    this.renderer.setStyle(
      this.tabList.nativeElement,
      'z-index',
      this.zIndex,
      RendererStyleFlags2.Important
    );
  }

  public trackBy(index: number) {
    return index;
  }

  ngOnDestroy() {
    this.componentRef?.destroy();
    this.routerSubscription?.unsubscribe();
    this.subscription?.unsubscribe();
  }

  private renderComponent(index: number) {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    if (this.localTabs[index].content) {
      this.componentRef = this.viewContainerRef.createComponent(
        this.localTabs[index].content.component
      );

      if (this.localTabs[index].content.props) {
        this.componentRef.instance.props = this.localTabs[index].content.props;
      }
      this.loadedTabComponentEvent.emit(this.componentRef);
    }
    this.changeDetector.detectChanges();
  }

  setActiveClass(index: number) {
    this.localTabs.forEach((tab) => (tab.active = false));
    this.localTabs[index].active = true;
    if (this.tabList.nativeElement.querySelector('.tab.active')) {
      this.renderer.removeClass(
        this.tabList.nativeElement.querySelector('.tab.active'),
        'active'
      );
    }
    if (
      !this.tabList.nativeElement
        .querySelectorAll('.tab')
        [index].className.includes('active')
    ) {
      this.renderer.addClass(
        this.tabList.nativeElement.querySelectorAll('.tab')[index],
        'active'
      );
    }
  }

  setIndicatorProperties(index: number) {
    const element = this.tabList.nativeElement.querySelectorAll('.tab')[index];
    const width = element.offsetWidth;
    const position = element.offsetLeft;

    this.renderer.setStyle(
      element.parentElement.querySelector('.tab-indicator'),
      'width',
      `${width}px`
    );
    this.renderer.setStyle(
      element.parentElement.querySelector('.tab-indicator'),
      'left',
      `${position}px`
    );
  }

  tabChange(index: number) {
    this.tobeActivedTabIndex = this.localTabs.indexOf(this.localTabs[index]);
    //TODO: confirmar al cambiar de tab
    // const activeTab = this.localTabs[this.currentActiveTabIndex];
    // if (this.tabService.tabCanChange(activeTab, this.tobeActivedTabIndex)) {
    //   this.tabChangeConfirmed(index);
    // }
    this.tabChangeConfirmed(index);
  }

  private tabChangeConfirmed(index: number) {
    if (this.localTabs[index].content) {
      if (this.currentActiveTabIndex !== this.tobeActivedTabIndex) {
        this.renderComponent(index);
      }
    } else {
      if (this.componentRef) {
        this.componentRef.destroy();
      }
    }

    this.currentActiveTabIndex = this.tobeActivedTabIndex;
    this.setIndicatorProperties(index);
    this.setActiveClass(index);

    this.tabChangeEvent.emit({
      currentActiveTabIndex: this.currentActiveTabIndex,
      tobeActivedTabIndex: this.tobeActivedTabIndex,
    });
    this.tabService.changeActiveTabIndex(this.currentActiveTabIndex);

    const tabActive = this.props?.tabs?.find((tab) => tab.active);
    if (tabActive) {
      this.tabService.changeActiveTabId(tabActive.tabId);
    }
  }

  public getTabId(value: Tab) {
    return `tab-${value.tabId}-${value.label.toLowerCase()}`;
  }
}
