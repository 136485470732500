import { Injectable } from '@angular/core';
import { ToastDto } from '@core/models/overlays/toast';
import { ToastSeverityEnum } from '@shared/enums/toast-severity.enum';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private toastData = new Subject<ToastDto>();
  public readonly onToastData$ = this.toastData.asObservable();

  constructor(private messageService: MessageService) {}

  openToast(toastData: ToastDto) {
    this.messageService.add({
      severity: toastData.severity,
      summary: toastData.summary,
      detail: toastData.detail,
      sticky: toastData.sticky,
    });
  }

  getToastData(): Subject<ToastDto> {
    return this.toastData;
  }

  setToastData(toastData: ToastDto) {
    this.toastData.next(toastData);
  }

  /**
   * @description Generate push info notity
   * @param  {ToastDto} toastData
   */
  info(toastData: ToastDto): void {
    this.setToast(ToastSeverityEnum.Info, toastData);
  }

  /**
   * @description Generate push info notity only detail
   * @param  {string} detail
   */
  infoDetail(detail: string): void {
    const toastData: ToastDto = {
      detail,
    };
    this.setToast(ToastSeverityEnum.Info, toastData);
  }

  /**
   * @description Generate push success notity
   * @param  {ToastDto} toastData
   */
  success(toastData: ToastDto): void {
    this.setToast(ToastSeverityEnum.Success, toastData);
  }

  /**
   * @description Generate push success notity only detail
   * @param  {string} detail
   */
  successDetail(detail: string): void {
    const toastData: ToastDto = {
      detail,
    };
    this.setToast(ToastSeverityEnum.Success, toastData);
  }

  /**
   * @description Generate push error notity
   * @param  {ToastDto} toastData
   */
  error(toastData: ToastDto): void {
    this.setToast(ToastSeverityEnum.Error, toastData);
  }

  /**
   * @description Generate push error notity only detail
   * @param  {string} detail
   */
  errorDetail(detail: string): void {
    if (typeof detail !== 'string') {
      detail = 'Service unavailable please try again later';
    }

    const toastData: ToastDto = {
      detail,
    };
    this.setToast(ToastSeverityEnum.Error, toastData);
  }

  /**
   * @description Generate push warning notity
   * @param  {ToastDto} toastData
   */
  warning(toastData: ToastDto): void {
    this.setToast(ToastSeverityEnum.Warn, toastData);
  }

  /**
   * @description Generate push warning notity only detail
   * @param  {string} detail
   */
  warningDetail(detail: string): void {
    const toastData: ToastDto = {
      detail,
    };
    this.setToast(ToastSeverityEnum.Warn, toastData);
  }

  setToast(severity: ToastSeverityEnum, toastData: ToastDto) {
    toastData.severity = severity;
    toastData = {
      ...toastData,
    };
    this.setToastData(toastData);
  }
}
