export class CustomObject {
  public static isEmpty(object: CommonFieldValue): boolean {
    return (
      object === undefined ||
      (typeof object === 'object' &&
        (object === null ||
          (object instanceof String && !object.length) ||
          (Array.isArray(object) && object.length === 0) ||
          Object.values(object).every(
            (prop: CommonFieldValue) =>
              (Array.isArray(prop) && prop.length === 0) ||
              (Array.isArray(prop) &&
                prop.length > 0 &&
                prop.some(
                  el =>
                    typeof el === 'object' &&
                    el !== null &&
                    CustomObject.isEmpty(el)
                )) ||
              prop === null ||
              (prop instanceof String && !prop.length) ||
              prop === '' ||
              (typeof prop === 'object' &&
                !Array.isArray(prop) &&
                prop !== null &&
                CustomObject.isEmpty(prop))
          )))
    );
  }

  public static filter<T>(object: {} | null, skipKeys: string[]) {
    return Object.fromEntries(
      Object.entries(object).filter(([key]) => !skipKeys.includes(key))
    ) as { [K in keyof T]: any };
  }

  public static getNestedProperty(obj: {}, key: string) {
    let value = obj[key];
    if (key.includes('.')) {
      value = key.split('.').reduce((o, i) => o[i], obj);
    }
    return value;
  }

  public static compare(
    obj1:
      | Record<string, CommonFieldValue>
      | Record<string, CommonFieldValue>[]
      | CommonFieldValue
      | CommonFieldValue[],
    obj2:
      | Record<string, CommonFieldValue>
      | Record<string, CommonFieldValue>[]
      | CommonFieldValue
      | CommonFieldValue[]
  ): boolean {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  public static diff(
    obj1: Record<string, CommonFieldValue>[] | CommonFieldValue[],
    obj2: Record<string, CommonFieldValue>[] | CommonFieldValue[]
  ): Record<string, CommonFieldValue>[] | CommonFieldValue[] {
    return Object.assign(
      {},
      ...Object.keys(obj1)
        .filter(key => obj1[key] !== obj2[key])
        .map(key => {
          return { [key]: obj1[key] };
        })
    );
  }

  public static mapKeys(
    obj: Record<string, CommonFieldValue>,
    func: (val) => string
  ) {
    return Object.keys(obj).reduce(
      (previous, current) => ({
        ...previous,
        [func(current)]: obj[current]
      }),
      {}
    );
  }

  public static compileNameOfProperty = <T>(name: keyof T) => name;

  public static hasProperty(
    obj: CommonFieldValue,
    propertyName: string
  ): boolean {
    return (
      Object.keys(obj).includes(propertyName) ||
      Object.values(obj)
        .filter(
          (value: CommonFieldValue) =>
            typeof value === 'object' && value !== null
        )
        .some((value: CommonFieldValue) =>
          CustomObject.hasProperty(value, propertyName)
        )
    );
  }
}
