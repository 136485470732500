import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { AuthorizeService } from '@core/services/authorize.service';
import { ROUTES_DEFINITION } from '@shared/constants/router-definitions.constant';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
})
export class AuthCallbackComponent implements OnInit {
  public error = false;

  constructor(
    private authService: AuthorizeService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    // check for error
    if (this.route.snapshot.fragment?.indexOf('error') >= 0) {
      this.error = true;
      return;
    }

    await this.authService.completeAuthentication();
    const url = sessionStorage.getItem('urlReturn');
    if (url === null) {
      this.router.navigate([ROUTES_DEFINITION.DOCUMENTS]);
    } else {
      this.router.navigateByUrl('/' + url);
    }
  }
}
