import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';

import { HttpService } from '@core/services/http.service';
import { AuthorizeService } from './services/authorize.service';
import { SnackbarService } from './services/snackbar.service';
import { SpinnerService } from './services/spinner.service';
import { LocalStorageService } from './services/local-storage.service';
import { DocumentsService } from '@feature/home/documents/service/documents.service';
import { OutlookService } from './services/outlook.service';
import { PatternService } from './services/pattern.service';
import { FileService } from './services/file.service';
import { TabService } from './services/tab.service';
import { GridService } from './services/grid.service';
import { ToastService } from './services/toast.service';

const PROVIDERS = [
  HttpService,
  SnackbarService,
  SpinnerService,
  AuthorizeService,
  LocalStorageService,
  DocumentsService,
  OutlookService,
  PatternService,
  FileService,
  TabService,
  GridService,
  ToastService,
];
@NgModule({
  imports: [CommonModule],
  providers: [...PROVIDERS, { provide: ErrorHandler }],
})
export class CoreModule {}
