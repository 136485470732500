export class BaseDto {
  constructor(dataJson?: any) {
    if (dataJson) {
      this.setValue(dataJson);
    }
  }

  // TODO: MUST PASS HERE MODEL OF JSON DATA TO AVOID SILENT ERRRORS
  // WHEN WRONG DTO'S ARE USED IN JSON PROPERTIES

  public setValue?(dataJson: any) {
    Object.assign(this, dataJson);
    return this;
  }
}
