export enum OrganizationCodeEnum {
  TibaSLU = 'TSL',
  TibaSpain = 'TBE',
  TibaEcuador = 'TEC',
  TibaMexico = 'TMX',
  TibaMozambique = 'TMZ',
  TibaRepDominicana = 'TDO',
  TibaPeru = 'TPE',
  TibaChina = 'TCN',
  TibaChinaSZ = 'TSZ',
  TibaChinaHK = 'THK',
  TibaUSA = 'TUS',
  TibaPortugal = 'TPT',
  TibaAngola = 'TAO',
  TibaCaboVerde = 'TCV'
}
