import { IconEnum } from '@shared/enums/icon.enum';
import { Component, OnInit, Inject } from '@angular/core';
import { Snackbar } from '@core/models/overlays/snackbar';
import { Button } from '@core/models/buttons/button';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
})
export class SnackbarComponent implements OnInit {
  public dismissBtn: Button = {
    icon: {
      name: IconEnum.Close,
      color: '!text-neutral-50',
    },
    onClick: () => this.close(),
  };

  constructor(
    public snackBarRef: MatSnackBarRef<SnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: Snackbar
  ) {}

  ngOnInit() {
    this.dismissBtn.text =this.data.dismissText
    this.dismissBtn = {
      ...this.dismissBtn
    }
    this.snackBarRef.afterDismissed().subscribe(() => {
      if (this.data.afterClose) {
        this.data.afterClose();
      }
    });
  }

  getIcon(): IconEnum {
    let icon: IconEnum;
    switch (this.data.type.split('-')[1]) {
      case 'success':
        icon = IconEnum.CheckCircle;
        break;
      case 'error':
        icon = IconEnum.Clear;
        break;
      case 'warning':
        icon = IconEnum.Warning;
        break;
      case 'info':
        icon = IconEnum.Info;
        break;
      case 'help':
        icon = IconEnum.Help;
        break;
    }
    return icon;
  }

  close() {
    this.snackBarRef.dismiss();
  }
}
