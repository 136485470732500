<span
  [matBadge]="props.content"
  [matBadgePosition]="props.position ? props.position : 'above after'"
  [matBadgeOverlap]="props.overlap ? props.overlap : false"
  [matBadgeColor]="props.color ? props.color : 'inform-600'"
  [matBadgeSize]="props.size ? props.size : 'medium'"
  [matBadgeHidden]="props.visibility ? props.visibility : false"
  [ngClass]="props.inline ? 'position-inline' : ''"
>
  <ng-content></ng-content>
</span>
