import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {
  constructor() {}

  /**
   * @description Get LocalStorage item with specified Key
   * @param  {string} key
   */
  getItem(key: string): any {
    return localStorage.getItem(key);
  }

  /**
   * @description Get JSON parsed LocalStorage item with specified Key
   * @param  {string} key
   */
  getJsonItem(key: string): any {
    return JSON.parse(localStorage.getItem(key));
  }

  /**
   * @description Set LocalStorage item with specified Key
   * @param  {string} key
   * @param  {any} value
   */
  setItem = (key: string, value: any): void => {
    localStorage.setItem(key, value);
  };

  /**
   * @description SetJSON parsed LocalStorage item with specified Key
   * @param  {string} key
   * @param  {any} value
   */
  setJsonItem = (key: string, value: any): void => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  /**
   * @description Removed LocalStorage item with specified Key
   * @param  {string} key
   */
  removeItem = (key: string): void => {
    localStorage.removeItem(key);
  };

  /**
   * @description Removed All LocalStorage items
   */
  clear = (): void => {
    localStorage.clear();
  };
}
