export enum IconEnum {
  Actions = 'actions',
  ActivityCenter = 'activity-center',
  Aerospace = 'aerospace',
  AirPlane = 'air-plane',
  ArrowGo = 'arrow-go',
  ArrowLeft = 'arrow-left',
  ArrowRight = 'arrow-right',
  Bell = 'bell',
  BellActive = 'bell-active',
  Blocked = 'blocked',
  Bolt = 'bolt',
  BuildingMaterials = 'building-materials',
  BulkDry = 'bulk-dry',
  BulkLiquids = 'bulk-liquids',
  Calendar = 'calendar',
  CarbonFootprint = 'carbon-footprint',
  Cash = 'cash',
  Chat = 'chat',
  Check = 'check',
  CheckBig = 'check-big',
  CheckCircle = 'check-circle',
  Clear = 'clear',
  Clock = 'clock',
  Close = 'close',
  CollapseDown = 'collapse-down',
  CollapseRight = 'collapse-right',
  CollapseUp = 'collapse-up',
  Compass = 'compass',
  Configuration = 'configuration',
  ContainerDangerousGoods = 'container-dangerous-goods',
  ContainerFlexitank = 'container-flexitank',
  ContainerNone = 'container-none',
  ContainerReefer = 'container-reefer',
  ContainerYard = 'container-yard',
  Copy = 'copy',
  Credit = 'credit',
  Delete = 'delete',
  DirectionDown = 'direction-down',
  DirectionFirst = 'direction-first',
  DirectionLast = 'direction-last',
  DirectionLeft = 'direction-left',
  DirectionRight = 'direction-right',
  DirectionUp = 'direction-up',
  Dragdrop = 'dragdrop',
  Drinks = 'drinks',
  Download = 'download',
  Edit = 'edit',
  Translate = 'translate',
  EFile = 'efile',
  Entities = 'entities',
  Error = 'error',
  ErrorRounded = 'error-rounded',
  Excel = 'excel',
  Exchange = 'exchange',
  Eye = 'eye',
  File = 'file',
  FileExcel = 'file-excel',
  Files = 'files',
  Filter = 'filter',
  Flag = 'flag',
  Folder = 'folder',
  FoodStuffs = 'foodstuffs',
  FullScreen = 'full-screen',
  Fumigation = 'fumigation',
  Gear = 'gear',
  GPS = 'gps',
  Hamburguer = 'hamburguer',
  Hammer = 'hammer',
  Healthcare = 'healthcare',
  Help = 'help',
  Home = 'home',
  Import = 'import',
  Info = 'info',
  Inquiries = 'inquiries',
  LastViewed = 'last-viewed',
  MenuCollapse = 'menu-collapse',
  MenuExpand = 'menu-expand',
  Minus = 'minus',
  Moon = 'moon',
  MultiMap = 'multi-map',
  Notifications = 'notifications',
  OceanBoat = 'ocean-boat',
  OceanBoatSide = 'ocean-boat-side',
  OceanFcl = 'ocean-fcl',
  OceanLcl = 'ocean-lcl',
  OpenExternal = 'open-external',
  Operation = 'operation',
  Options = 'options',
  Person = 'person',
  Phone = 'phone',
  Plus = 'plus',
  PointerDown = 'pointer-down',
  PointerLeft = 'pointer-left',
  PointerRight = 'pointer-right',
  PointerUp = 'pointer-up',
  Properties = 'properties',
  RailTrain = 'rail-train',
  Receive = 'receive',
  Reset = 'reset',
  RoadTruck = 'road-truck',
  Save = 'save',
  Scale = 'scale',
  Search = 'search',
  Send = 'send',
  SolarEnergy = 'solar-energy',
  Sort = 'sort',
  Star = 'star',
  StarEmpty = 'star-empty',
  Sun = 'sun',
  Switch = 'switch',
  Tasks = 'tasks',
  TimelineAfter = 'timeline-after',
  TimelineAfterAir = 'timeline-after-air',
  TimelineAnchor = 'timeline-anchor',
  TimelineBefore = 'timeline-before',
  TimelineBeforeAir = 'timeline-before-air',
  Upload = 'upload',
  UserCard = 'user-card',
  Warehouse = 'warehouse',
  Warning = 'warning',
  Wimatch = 'wimatch',
  Wind = 'wind',
  World = 'world',
  Quotations = 'quotations',
  Dashboard = 'apps',
  Automotive = 'automotive',
  HotelLogistics = 'hotel-logistics',
  Retail = 'retail',
  ControlPanel = 'control-panel'
}
