import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loadingTextLocal: string | null = null;
  private initiaLoadingLocal: boolean = true;
  public isLoadingChange: EventEmitter<boolean>;
  public get isLoading(): boolean {
    return this.loadingCount > 0;
  }

  get initiaLoading(): boolean {
    return this.initiaLoadingLocal;
  }
  set initiaLoading(value: boolean) {
    this.initiaLoadingLocal = value;
  }

  get loadingText(): string | null {
    return this.loadingTextLocal;
  }
  set loadingText(value: string | null) {
    this.loadingTextLocal = value;
  }

  private loadingCount: number;

  constructor() {
    this.isLoadingChange = new EventEmitter<boolean>();
    this.loadingCount = 0;
  }

  enableLoading() {
    this.loadingCount++;
    this.isLoadingChange.emit(this.isLoading);
  }

  disableLoading() {
    this.loadingCount--;
    this.isLoadingChange.emit(this.isLoading);
  }
}
