import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastService } from '@core/services/toast.service';
import { ToastPositionEnum } from '@shared/enums/toast-position.enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
})
export class ToastComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  public toastPosition: string;
  public constructor(private toastService: ToastService) {
    this.toastPosition = ToastPositionEnum.BottomCenter;
  }

  ngOnInit(): void {
    this.subscription.add(
      this.toastService.onToastData$.subscribe((toastData) => {
        if (toastData) {
          this.toastService.openToast(toastData);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
