@if (isLoading && initiaLoading) {
  <div class="app-root">
    <div class="loader"></div>
  </div>
}
<header></header>
<main>
  <router-outlet></router-outlet>
</main>
<app-toast></app-toast>
