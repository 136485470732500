// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//--------------  IMPORTANTE, SI SE AGREGA ALGUNA PROPIEDAD AVISAD A ARQUITECTURA PARA MODIFICAR EL FICHERO environment.pipeline.ts Y PIPELINES -------------//

const API_URL = `api.tibagroup.com`;
const LOCALHOST_URL = `https://localhost:4200/`;
const OUTLOOK_URL = 'https://outlook-app-dev.tibagroup.com/';

const getDevUrl = (apiFragment: string) => {
  return `https://dora-dev-apis.tibagroup.com/${apiFragment}-api`;
};

export const environment = {
  name: 'development',
  web: false,
  production: false,
  masterApiUrl: getDevUrl('masterdata'),
  servicesApiUrl: getDevUrl('services'),
  entitiesApiUrl: getDevUrl('entities'),
  operationsApiUrl: getDevUrl('operations'),
  packingApiUrl: getDevUrl('packing'),
  transportsApiUrl: getDevUrl('transports'),
  documentsApiUrl: getDevUrl('documents'),
  billingApiUrl: getDevUrl('billing'),
  quotationApiUrl: getDevUrl('quotes'),
  routingApiUrl: getDevUrl('routing'),
  messagesApiUrl: getDevUrl('messages'),
  auditApiUrl: getDevUrl('audit'),
  filesApiUrl: getDevUrl('files'),
  itemsApiUrl: getDevUrl('items'),
  partiesApiUrl: getDevUrl('parties'),
  customsApiUrl: getDevUrl('customs'),
  userManagementApi: `https://tiba-dev-user-management-${API_URL}`,
  identityserver: `https://dev-identity.tibagroup.com`,
  client_id: 'dora',
  redirect_uri: `${OUTLOOK_URL}#/auth-callback`,
  post_logout_redirect_uri: OUTLOOK_URL,
  silent_redirect_uri: `${OUTLOOK_URL}silent-renew.html`,
  scope:
    'openid profile dora_api tiba-user-management purchase_and_quote_api dora_entities_api tiba_md_api IdentityServerApi',
  appInsights: {
    instrumentationKey: '973695fd-de21-4f15-a1ab-a08642de16f2',
    connectionString: 'ConnStr',
  },
  outlookClientId: '2ec035fc-a89e-4a45-90cc-b4832e1fd0da',
  outlookTenantId: 'f4716c71-0bcb-4d17-b242-72328973bc32',
  outlookLoginUri: 'https://login.microsoftonline.com',
  outlookRedirectUri: '/auth-callback',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
