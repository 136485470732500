<div
  class="flex align-middle"
  (click)="!disabled && props.onClick && props.onClick($event)"
  >
  @if (props.icon) {
    <mat-icon
      [svgIcon]="props.icon.name"
      [ngClass]="getClassIcon()"
    ></mat-icon>
  }
  @if (props.text) {
    <span [ngClass]="getClassName()">{{ props.text }}</span>
  }
  @if (props.boldText) {
    <strong [ngClass]="getClassName()"
      >&nbsp;{{ props.boldText }}</strong
      >
    }
  </div>
