import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from '@shared/modules/angular-material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { SnackbarComponent } from './components/overlays/snackbar/snackbar.component';
import { StandardButtonComponent } from './components/buttons/standard/base/standard-button.component';

import { SpecialButtonComponent } from './components/buttons/special/base/special-button.component';
import { SpecialCloseButtonComponent } from './components/buttons/special/close/special-close-button.component';
import { SpecialPrimaryButtonComponent } from './components/buttons/special/primary/special-primary-button.component';
import { SpecialUnreadButtonComponent } from './components/buttons/special/unread/special-unread-button.component';
import { StandardDangerButtonComponent } from './components/buttons/standard/danger/standard-danger-button.component';
import { StandardPrimaryButtonComponent } from './components/buttons/standard/primary/standard-primary-button.component';
import { StandardSecondaryButtonComponent } from './components/buttons/standard/secondary/standard-secondary-button.component';
import { IconButtonComponent } from './components/buttons/icon/base/icon-button.component';
import { BadgeComponent } from './components/indicators/badge/badge.component';
import { PillComponent } from './components/indicators/pill/pill.component';
import { SpinnerComponent } from './components/indicators/spinner/spinner.component';
import { TableComponent } from './components/datagrid/table/table.component';
import { PrimeNgModule } from './modules/prime-ng.module';
import { TabsComponent } from './components/layout/tabs/tabs.component';
import { FilesPipe } from './pipes/files.pipe';
import { TablePaginatorComponent } from './components/datagrid/table/table-paginator/table-paginator.component';
import { MessageService } from 'primeng/api';
import { ToastComponent } from './components/overlays/toast/toast.component';

const importsExports = [
  AngularMaterialModule,
  PrimeNgModule,
  CommonModule,
  FormsModule,
  HttpClientModule,
  ReactiveFormsModule,
  RouterModule,
];

const declarationExports = [
  SnackbarComponent,
  StandardButtonComponent,
  SpecialButtonComponent,
  SpecialCloseButtonComponent,
  SpecialPrimaryButtonComponent,
  SpecialUnreadButtonComponent,
  StandardDangerButtonComponent,
  StandardPrimaryButtonComponent,
  StandardSecondaryButtonComponent,
  IconButtonComponent,
  BadgeComponent,
  PillComponent,
  SpinnerComponent,
  TableComponent,
  TabsComponent,
  TablePaginatorComponent,
  ToastComponent,
];

const pipes = [FilesPipe];

const providers = [MessageService];
@NgModule({
  declarations: [...declarationExports, ...pipes],
  imports: [...importsExports],
  exports: [...declarationExports, ...importsExports, ...pipes],
  providers: [...providers],
})
export class SharedModule {}
