
<p-table
  [value]="getDataSource()"
  [tableStyle]="{ 'min-width': '25rem' }"
  [resizableColumns]="props?.resizableColumns ? true : false"
  [columnResizeMode]="props?.columnResizeMode ? props.columnResizeMode : ''"
  [styleClass]="props?.styleClass ? props.styleClass : ''"
  [(selection)]="selectedData"
  [metaKeySelection]="metaKeySelection"
  [dataKey]="props?.idKey"
  (onRowSelect)="onRowSelect($event)"
  (onRowUnselect)="onRowUnselect($event)"
  [paginator]="props.paginable"
  [first]="0"
  [rows]="10"
  [showPageLinks]="false"
  [showJumpToPageDropdown]="false"
  [showFirstLastIcon]="false"
  [showCurrentPageReport]="true"
  responsiveLayout="stack"
  [breakpoint]="'620px'"
  >
  >
  <ng-template pTemplate="header" let-columns>
    <tr class="header-table">
      @if (props?.checkable) {
        <th scope="col" style="width: 4rem"></th>
      }
      @for (col of props.columns; track col) {
        <th
          pResizableColumn
          [style]="col.style"
          [hidden]="col?.hidden"
          scope="col"
          >
          {{ col.header }}
        </th>
      }
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-columnData
    let-columns="columns"
    let-editing="editing"
    let-rowIndex="rowIndex"
    >
    <tr>
      @if (props?.checkable) {
        <td>
          <p-tableCheckbox
            (click)="selectRow()"
            [value]="columnData"
          ></p-tableCheckbox>
        </td>
      }
      @for (col of props.columns; track col) {
        <td
          [pEditableColumn]="col.editable ? columnData[col.columnDef] : ''"
          [hidden]="col?.hidden"
          >
          <span class="p-column-title font-bold"> {{ col.header }} </span>
          @if (!col.editable) {
            <div>
              @if (!col.isFileIcon) {
                <div>
                  <span>
                    {{ columnData[col.columnDef] }}
                  </span>
                </div>
              } @else {
                <i
                  [ngClass]="columnData[col.columnDef] | files"
                  style="font-size: 1.125rem"
                ></i>
              }
            </div>
          } @else {
            @if (col.editCellTemplate === 'input') {
              <p-cellEditor>
                <ng-template pTemplate="input">
                  @if (
                    isFirstEmail(
                    rowIndex,
                    columnData?.documentType?.description
                    )) {
                    <div
                      >
                      <span>
                        {{ columnData[col?.columnDef] }}
                      </span>
                    </div>
                  } @else {
                    <input
                      pInputText
                      type="text"
                      required
                      [pKeyFilter]="blockChars"
                      [(ngModel)]="columnData[col.columnDef]"
                    />
                  }
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ columnData[col.columnDef] }}
                  </ng-template>
                </p-cellEditor>
              }
              @if (col.editCellTemplate === 'select') {
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    @if (
                      isFirstEmail(
                      rowIndex,
                      columnData[col?.columnDef]?.description
                      )) {
                      <div
                        >
                        <span>
                          {{ columnData[col?.columnDef]?.description }}
                        </span>
                      </div>
                    } @else {
                      <p-dropdown
                        #dropdown
                        placeholder="Select document type"
                        [options]="documentsTypes"
                        [filter]="true"
                        [filterBy]="col?.filterBy ? col?.filterBy : ''"
                        optionLabel="description"
                        [style]="{ width: '100%' }"
                        [(ngModel)]="columnData[col?.columnDef]"
                        appendTo="body"
                        [showClear]="true"
                      ></p-dropdown>
                    }
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{
                    columnData[col?.columnDef]?.description
                    ? columnData[col?.columnDef]?.description
                    : "Click to select document"
                    }}
                  </ng-template>
                </p-cellEditor>
              }
            }
          </td>
        }
      </tr>
    </ng-template>
  </p-table>
  <div class="row py-3">
    <div class="col-auto me-auto"></div>
    <div class="pt-2 col-auto">
      <app-standard-primary-button
        [props]="saveButton"
      ></app-standard-primary-button>
    </div>
  </div>
