import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { SpinnerService } from '@core/services/spinner.service';
import { LoadingService } from '@core/services/loading.service';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(
    private spinnerService: SpinnerService,
    private loadingService: LoadingService
  ) {}

  removeRequest(request: HttpRequest<any>) {
    const i = this.requests.indexOf(request);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }

    if (this.requests.length === 0) {
      this.loadingService.disableLoading();
    }
    this.spinnerService.setVisibility(this.requests.length > 0);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.requests.push(request);
    this.spinnerService.setVisibility(true);
    this.loadingService.enableLoading();
    return new Observable((observer) => {
      const subscription = next.handle(request).subscribe(
        (event) => {
          if (event instanceof HttpResponse) {
            this.removeRequest(request);
            observer.next(event);
          }
        },
        (err) => {
          this.removeRequest(request);
          observer.error(err);
        },
        () => {
          this.removeRequest(request);
          observer.complete();
        }
      );
      return () => {
        this.removeRequest(request);
        subscription.unsubscribe();
      };
    });
  }
}
