import { Injectable, Testability } from '@angular/core';
import { OUTLOOK } from '@shared/constants/outlook.constant';

import { DocDownload } from '@core/models/outlook/docDownload.model';
import {
  OutlookUserEmailInfo,
  OutlookEmailInfo,
  OutlookEmailInfoHelper,
} from '@core/models/outlook/outlook.model';

import { OutlookMode } from '@shared/enums/outlook/outlook.enum';
import { ToastService } from './toast.service';
import { ToastDto } from '@core/models/overlays/toast';

declare const Office: any;
const OP_FRAGMENT: string = ' OP';

@Injectable({
  providedIn: 'root',
})
export class OutlookService {
  outlookToken: string = null;
  public operationNumber: string = null;

  constructor(private readonly toastService: ToastService) {}

  /**
   * @description Get all info for Outlook item
   * @returns {OutlookEmailInfo}
   */
  getOutlookInfo(): OutlookEmailInfo {
    return OutlookEmailInfoHelper.mapToObject(Office?.context?.mailbox);
  }

  /**
   * @description Generate a new Outlook token
   * @returns {OutlookEmailInfo}
   */
  getOutlookToken(): void {
    Office?.context?.mailbox?.getCallbackTokenAsync({ isRest: true }, (result) => {
      this.outlookToken = result.value;
    });
  }

  /**
   * @description Get Outlook mode - eg: write, read
   * @returns {OutlookMode}
   */
  getOutlookMode(): OutlookMode {
    return Office?.context?.mailbox?.item.itemId !== undefined
      ? OutlookMode.read
      : OutlookMode.write;
  }

  /**
   * @description Get Outlook is read mode
   * @returns {OutlookMode}
   */
  isOutlookOpenReadMode(): boolean {
    return this.getOutlookMode() === OutlookMode.read;
  }

  /**
   * @description Get Outlook is write mode
   * @returns {OutlookMode}
   */
  isOutlookOpenWriteMode(): boolean {
    return this.getOutlookMode() === OutlookMode.write;
  }

  /**
   * @description Get subject from mail using Office API
   * @returns {string}
   */
  getSubject(): string {
    return this.getOutlookInfo().subject;
  }

  async getSubjectAsync(): Promise<string> {
    return new Promise((resolve: any) => {
      Office?.context?.mailbox?.item.subject.getAsync((asyncResult) => {
        if (asyncResult.status == Office?.AsyncResultStatus.Failed) {
          resolve('');
        } else {
          const subject = asyncResult.value;
          const index = subject.indexOf(OP_FRAGMENT);
          const subjectOP = subject.substring(index + 1, index + 11);
          resolve(subjectOP);
        }
      });
    });
  }

  /**
   * @description Detect email is Forwanding
   * @returns {boolean}
   */
  emailIsForwarding(): boolean {
    return this.getOutlookInfo().isForwarding;
  }

  /**
   * @description Get From User and Email Info
   * @returns {OutlookUserEmailInfo[]}
   */
  getFrom(): OutlookUserEmailInfo {
    return this.getOutlookInfo().from;
  }

  /**
   * @description Get From User and Email Info
   * @returns {OutlookUserEmailInfo[]}
   */
  getTo(): OutlookUserEmailInfo[] {
    return this.getOutlookInfo().to;
  }

  /**
   * @description Get Sender User and Email Info
   * @returns {OutlookUserEmailInfo}
   */
  getSender(): OutlookUserEmailInfo {
    return this.getOutlookInfo().sender;
  }

  /**
   * @description Get CC User and Email Info
   * @returns {OutlookUserEmailInfo[]}
   */
  getCarbonCopy(): OutlookUserEmailInfo[] {
    return this.getOutlookInfo().carbonCopy;
  }

  /**
   * @description Get Username from getTo() User and Email Info
   * @returns  {string}
   */
  getUserToFrom(): string {
    const userName: string = this.getOutlookInfo().userName;
    return userName ?? 'DORA';
  }

  /**
   * @description Get Attachment Files, exclude images on body
   * @returns {DocDownload[]} Minimal DocDownload with name, extension, outlookId and id = '0'
   */
  getAttachmentFiles(): DocDownload[] {
    return this.getOutlookInfo().attachmentFiles;
  }

  /**
   * @description Call Outlook API and get document convert on Minimal DocDowload with name, extension, outlookId and base64
   * @param  {DocDownload} document
   */
  async getFileFromOutlookCloud(document: DocDownload): Promise<DocDownload> {
    if (document && document.outlookId) {
      const fileFromOutlook: DocDownload =
        await Office?.context?.mailbox?.item.getAttachmentContentAsync(
          document.outlookId,
          (result) => {
            document.base64 = result.value.content;
            document.format = result?.value?.format
              ? result?.value?.format
              : '';
            return document;
          }
        );

      return await document;
    }

    return;
  }

  /**
   * @description Call Outlook API and get document convert on Minimal DocDowload with name, extension, outlookId and byteArray
   * @param  {DocDownload} document
   */
  async getEmailFromOutlookCloud(): Promise<string | void> {
    const base64email = await this.getCurrentItem(this.outlookToken).then(
      (base64) => {
        return base64;
      }
    );

    return await base64email;
  }

  /**
   * @description Attach de file on Outlook mail using Office API
   * @param  {string} base64
   * @param  {string} fileName
   */
  attachFileFromBase64(base64: string, fileName: string): void {
    let toastData: ToastDto = {
      detail: '',
      summary: '',
    };
    Office?.context?.mailbox?.item.addFileAttachmentFromBase64Async(
      base64,
      fileName,
      { asyncContext: null },
      function (asyncResult) {
        if (asyncResult.status === Office?.AsyncResultStatus.Failed) {
          toastData.detail = asyncResult.error.message;
          toastData.summary = 'Error';
          this.toastService.error(toastData);
        } else {
          toastData.detail = 'Succesfully attachment file: ' + fileName;
          toastData.summary = 'Success';
          this.toastService.success(toastData);
        }
      }
    );
  }

  /**
   * @description Get ItemId from email
   * @returns {string}
   */
  getItemRestId(): string {
    if (Office?.context?.mailbox?.diagnostics.hostName === OUTLOOK.HOSTANME.IOS) {
      return Office?.context?.mailbox?.item.itemId;
    } else {
      return Office?.context?.mailbox?.convertToRestId(
        Office?.context?.mailbox?.item.itemId,
        Office?.MailboxEnums.RestVersion.v2_0
      );
    }
  }
  /**
   * @param  {} accessToken Token from Outlook entity
   */
  async getCurrentItem(accessToken: string): Promise<string | void> {
    let getMessageUrl =
      Office?.context?.mailbox?.restUrl +
      '/v2.0/me/messages/' +
      this.getItemRestId() +
      '/$value';
    try {
      const base64Email = await fetch(getMessageUrl, {
        method: 'GET',
        headers: new Headers({
          Authorization: 'Bearer ' + accessToken,
        }),
      })
        .then((response) => response.blob())
        .then((blob) => this.convertEmailToBase64(blob))
        .then((base64) => base64.toString().split(',')[1])
        .then((transformBase64) => {
          return transformBase64;
        })
        .catch((error) => {
          //TODO: mostar mensaje
          //this._notifyService.error(error);
        });

      return await base64Email;
    } catch (error) {
      //TODO: mostar mensaje
      //this._notifyService.error(error);
      return null;
    }
  }

  /**
   * @description Convert email from Blob to Base64
   * @param  {any} blob
   * @returns {Promise<string | ArrayBuffer>}
   */
  async convertEmailToBase64(blob: any): Promise<string | ArrayBuffer> {
    return await new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  /**
   * @description Set state to Office context
   * @param  {string} fieldName
   * @param  {any} value
   * @returns void
   */
  setState(fieldName: string, value: any): void {
    Office?.context?.roamingSettings.set(fieldName, value);
  }

  /**
   * @description Get state from Office context
   * @param  {string} fieldName
   * @returns void
   */
  getState(fieldName: string): any {
    return Office?.context?.roamingSettings.get(fieldName);
  }

  /**
   * @description Get state from Office context
   * @param  {string} fieldName
   * @returns void
   */
  removeState(fieldName: string): any {
    Office?.context?.roamingSettings.remove(fieldName);
  }

  saveState(): void {
    Office?.context?.roamingSettings.saveAsync(function (asyncResult) {
      if (asyncResult.status == Office?.AsyncResultStatus.Failed) {
        console.error('State save failed. Error: ' + asyncResult.error.message);
      } else {
        console.info('State saved.');
      }
    });
  }
}
