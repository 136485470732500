import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-table-paginator',
  templateUrl: './table-paginator.component.html',
  styleUrls: ['./table-paginator.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TablePaginatorComponent implements OnChanges, OnInit {
  @Input() disabled = false;
  @Input() length: number;
  @Input() pageSize = 20;
  @Input() pageIndex: number;
  @Input() pageSizeOptions: number[] = [20, 40, 60, 100];

  // NOTE: TO EXPOSE ALL MatPaginator METHODS OUTSIDE
  @ViewChild(MatPaginator) public matPaginator: MatPaginator;

  @Output() page = new EventEmitter<PageEvent>();

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {
    const pageIndex = changes.pageIndex;
    const pageSize = changes.pageSize;
    const length = changes.length;

    if (pageIndex !== undefined) {
      this.pageIndex = this.getNumericValue(pageIndex);
    }
    if (pageSize !== undefined) {
      this.pageSize = this.getNumericValue(pageSize);
    }
    if (length !== undefined) {
      this.length = this.getNumericValue(length);
    }
  }

  private getNumericValue(numericChange: SimpleChange) {
    const oldValue = numericChange?.previousValue as number;
    const newValue = numericChange?.currentValue as number;
    return newValue !== oldValue ? newValue : oldValue;
  }

  public onPageChange($event: PageEvent) {
    this.pageIndex = $event.pageIndex;
    this.pageSize = $event.pageSize;
    this.length = $event.length;
    this.page.emit($event);
  }
}
