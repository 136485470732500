export const OUTLOOK = {

    REQUEST_STATUS: {
        SUCCEEDED: 'succeeded'
    },

    HOSTANME: {
        IOS: 'OutlookIOS'
    },

    EXTENSION: {
        EML: 'eml',
        MSG: 'msg'
    },

};