import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { AuthorizeService } from '@core/services/authorize.service';
import { OutlookService } from '@core/services/outlook.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  private return = '';

  constructor(
    private readonly route: ActivatedRoute,
    private readonly authorizeService: AuthorizeService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.return = params.redirect || '';
    });
    this.authorizeService.login();
    sessionStorage.setItem('urlReturn', this.return);
  }
}
