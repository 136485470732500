import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';

import { StandardButtonComponent } from '../base/standard-button.component';

@Component({
  selector: 'app-standard-primary-button',
  templateUrl: '../base/standard-button.component.html',
  encapsulation: ViewEncapsulation.None
})
export class StandardPrimaryButtonComponent
  extends StandardButtonComponent
  implements OnInit
{
  constructor(public el: ElementRef, public renderer: Renderer2) {
    super(el, renderer);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.renderer.addClass(
      this.el.nativeElement.firstElementChild,
      'primary-button'
    );
  }
}
