import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserUtils } from '@azure/msal-browser';
import { AuthorizeGuard } from '@core/guards/authorize/authorize.guards';
import { AuthCallbackComponent } from '@feature/account/auth-callback/auth-callback.component';
import { LoginComponent } from '@feature/account/login/login.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthorizeGuard],
    loadChildren: () =>
      import('./feature/home/home.module').then((m) => m.HomeModule),
  },
  { path: 'login', component: LoginComponent },
  { path: 'auth-callback', component: AuthCallbackComponent },
  { path: '**', redirectTo: '/error/500' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? 'enabledNonBlocking'
          : 'disabled', // Set to enabledBlocking to use Angular Universal
      //useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
