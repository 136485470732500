import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private visibility = new BehaviorSubject<boolean>(false);
  public readonly onVisibilityChange$ = this.visibility.asObservable();

  constructor() {}

  setVisibility(value: boolean) {
    this.visibility.next(value);
  }
}
