import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';

import { AuthorizeService } from '@core/services/authorize.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

const contentTypeString = 'Content-Type';
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private readonly authorizeService: AuthorizeService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let headers = request.headers || new HttpHeaders();
    headers = headers.append(
      'Authorization',
      this.authorizeService.authorizationHeaderValue
    );
    if (this.isHeaderContentTypeMultipart(headers)) {
      headers = headers.delete(contentTypeString);
    } else {
      headers = headers.append(contentTypeString, 'application/json');
    }
    headers = headers.append(
      'Organization',
      `${this.authorizeService.organizationId}`
    );
    headers = headers.append(
      'TimeZone',
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    request = request.clone({ headers });
    return next.handle(request);
  }

  private isHeaderContentTypeMultipart(headers: HttpHeaders): boolean {
    const contentType = headers.get(contentTypeString);
    return contentType && contentType === 'multipart/form-data';
  }
}
