import { Pipe, PipeTransform } from '@angular/core';
import { FileExtensionEnum } from '@core/enums/file-extension.enum';
@Pipe({
  name: 'files',
})
export class FilesPipe implements PipeTransform {
  transform(value: any): any {
    switch (value) {
      case FileExtensionEnum.PDF:
        return 'pi pi-file-pdf';
      case FileExtensionEnum.XLSX:
        return 'pi pi-file-excel';
      case FileExtensionEnum.XLS:
        return 'pi pi-file-excel';
      case FileExtensionEnum.DOCX:
        return 'pi pi-file-word';
      case FileExtensionEnum.DOC:
        return 'pi pi-file-word';
      case FileExtensionEnum.EML:
        return 'pi pi-envelope';
      default:
        return 'pi pi-file';
    }
    return value;
  }
}
