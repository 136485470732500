import { DocumentTypeResponseDto } from '@feature/home/documents/upload/models/document-type-response-dto';

export class DocDownload {
  constructor(
    public id?: number,
    public name?: string,
    public extension?: string,
    public byteArray?: any,
    public outlookId?: string,
    public documentType: DocumentTypeResponseDto = new DocumentTypeResponseDto(),
    public base64?: string,
    public format?: string,
    public overrite: boolean = true
  ) {}
}

export class DocDownloadHelper {
  static DefaultObject(): DocDownload {
    return new DocDownload(
      1,
      null,
      null,
      null,
      null,
      new DocumentTypeResponseDto(),
      null,
      null,
      true
    );
  }

  static mapToObject(result): DocDownload {
    return new DocDownload(
      result.Id,
      result.Name,
      result.Extension,
      result.ByteArray,
      null,
      result.documentType,
      result.Base64,
      result.Format,
      result.Overrite
    );
  }

  static mapToObjectList(result): any[] {
    const items = [];
    const itemsToTransform = result.Data || result;
    itemsToTransform.forEach((item) => {
      items.push(this.mapToObject(item));
    });

    return items;
  }
}
