<div class="container-fluid !p-0">
  <div class="row">
    <div class="col-12 flex items-center justify-center">
      @if (data.type && !data.icon) {
        <mat-icon
          [svgIcon]="getIcon()"
          class="mr-2"
        ></mat-icon>
      }
      @if (!data.type && data.icon) {
        <mat-icon
          [svgIcon]="data.icon"
          class="mr-2"
        ></mat-icon>
      }
      <p [ngClass]="data.message.color ? data.message.color : ''">
        {{ data.message.text }}
      </p>
      @if (data.dismissable) {
        <app-standard-button
          [props]="dismissBtn"
          class="ml-4"
        ></app-standard-button>
      }
    </div>
  </div>
</div>
