import { CustomString } from '@shared/utils/string';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Snackbar } from '@core/models/overlays/snackbar';
import { SnackbarComponent } from '@shared/components/overlays/snackbar/snackbar.component';
import { SnackbarType } from '@core/enums/snackbar-type.enum';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  openSnackbar(snackBarData: Snackbar) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      ...(!snackBarData.dismissable
        ? {
            duration: snackBarData.duration ? snackBarData.duration : 3000,
          }
        : {}),
      horizontalPosition: snackBarData.horizontalPosition
        ? snackBarData.horizontalPosition
        : 'center',
      verticalPosition: snackBarData.verticalPosition
        ? snackBarData.verticalPosition
        : 'bottom',
      ...(snackBarData.type && !snackBarData.bgColor
        ? {
            panelClass: ['snackbar',snackBarData.type],
          }
        : {}),
      ...(!snackBarData.type && snackBarData.bgColor
        ? {
            panelClass: ['snackbar',snackBarData.bgColor],
          }
        : {}),
      data: {
        type: snackBarData.type,
        dismissable: snackBarData.dismissable ?? false,
        dismissText: snackBarData.dismissText ?? null,
        ...(snackBarData.icon ? snackBarData.icon : {}),
        message: {
          text: snackBarData.message.text,
          ...(snackBarData.message && snackBarData.message.color
            ? {
                color: snackBarData.message.color,
              }
            : {}),
        },
        ...(snackBarData.afterClose
          ? {
              afterClose: snackBarData.afterClose,
            }
          : {}),
      },
    });
  }

  openCatchErrorSnackbar(
    message?: string,
    callback?: () => void,
    snackBarData?: Snackbar
  ) {
    return catchError((error: HttpErrorResponse) => {
      const errorMsg =
        error.error && typeof error.error === 'string'
          ? error.error
          : 'Something has gone wrong';
      this.openErrorSnackbar(message ? message : errorMsg, snackBarData);
      if (callback) {
        callback();
      }
      return throwError(error);
    });
  }

  openSuccessSnackbar(message: string, snackBarData?: Snackbar) {
    this.openSnackbar(
      this.getSnackbar(message, SnackbarType.success, snackBarData)
    );
  }

  openErrorSnackbar(message?: string, snackBarData?: Snackbar) {
    message = CustomString.IsNullOrBlank(message)
      ? 'Some error occurred'
      : message;
    this.openSnackbar(
      this.getSnackbar(message, SnackbarType.error, snackBarData)
    );
  }

  openInfoSanckbar(message: string, snackBarData?: Snackbar) {
    this.openSnackbar(
      this.getSnackbar(message, SnackbarType.info, snackBarData)
    );
  }

  openWarnSanckbar(message: string, snackBarData?: Snackbar) {
    this.openSnackbar(
      this.getSnackbar(message, SnackbarType.warning, snackBarData)
    );
  }

  private getSnackbar(
    message: string,
    type: SnackbarType,
    snackBarData?: Snackbar
  ): Snackbar {
    return {
      ...snackBarData,
      type: type,
      message: {
        text: message,
      },
    };
  }
}
